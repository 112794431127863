import axios from './config'

export function login(data) {
    return axios({
        url: '/login',
        method: "post",
        data
    })
}

// 获取首页数据
export function homeData(){
    return axios({
        url: '/homeData',
        method: "get"
    })
}

//添加客户
export function addInfo(data){
    return axios({
        url: '/addInfo',
        method: "post",
        data
    })
}
//获取客户信息
export function getInfo(params){
    return axios({
        url: '/getInfo',
        method: "get",
        params
    })
}

// 编辑客户
export function editInfo(data){
    return axios({
        url: '/editInfo',
        method: "put",
        data
    })
}
// 删除客户
export function delInfo(params){
    return axios({
        url: '/delInfo',
        method: "delete",
        params
    })
}
// 删除全部客户
export function delAllInfo(params){
  return axios({
      url: '/delAllInfo',
      method: "delete",
      params
  })
}
